import {
    CATALOGUE_MODE,
    NO_CATEGORIES_ENABLED_FOR_SALESMODE,
    TCPOS,
} from "@constants";
import { io } from "socket.io-client";
import { snapshot } from "valtio";

import React from "react";
import { useDispatch } from "react-redux";

import {
    setIsAppPaused,
    setIsLicenseProblem,
    setIsTechProblemModalOpened,
    store,
} from "@store";

import { connectWebSocket, handleLedControl } from "@helpers/general";

import { checkforLicenseAvailability } from "@components/OrderTaking/Helpers";
import {
    setCarte,
    setOutOfStockItems,
} from "@components/OrderTaking/Store/feature";
import {
    setSelectedShopApp,
    syncStore,
} from "@components/Synchronization/store";

import {
    kioskStore,
    setConnectedLedPort,
    setIsLedActive,
    setIsProjectMustBeUpdated,
    setNavigationIndex,
    setSelectedSaleModeOrderTaking,
} from "@pages/Kiosk/store";
import { setIsAppMustBeResynchronise } from "@pages/Kiosk/store/actions";

export function useSocketConnection(): void {
    const { middlewareApiUrl, posEditor } = snapshot(store);
    const { project } = snapshot(kioskStore);
    const dispatch = useDispatch();
    const { machineIpAddress, selectedShopApp } = snapshot(syncStore);
    const timestamp = new Date().getTime();
    // eslint-disable-next-line
    const shopDetail: any = JSON.parse(localStorage.getItem("shopDetail")!);
    const oldUuidSession = localStorage.getItem("uuidSession");
    // eslint-disable-next-line
    const ledConfig = JSON.parse(localStorage.getItem("ledConfig") as any);

    function getCatalogueCarte() {
        return fetch(
            `${middlewareApiUrl}/kiosk/${shopDetail?.schema}/${shopDetail?.id}/card?timestamp=${timestamp}`,
            { cache: "no-store" }
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error === true) {
                    console.log("There's an issue while getting catalog");
                }
                const { selectedSaleModeOrderTaking } = snapshot(kioskStore);
                const salesModeActiveCategory = selectedSaleModeOrderTaking
                    ? data.globalCategory.filter(
                          // eslint-disable-next-line
                          (cat: any) =>
                              cat.KioskVisibility[selectedSaleModeOrderTaking]
                      )
                    : [];

                dispatch(setCarte(data));
                console.log(`new catalogue card setted with success`);
                if (
                    selectedSaleModeOrderTaking &&
                    salesModeActiveCategory.length === 0
                ) {
                    console.log(
                        "probleme technique, aucune categorie active pour le mode de vente",
                        selectedSaleModeOrderTaking
                    );

                    setIsTechProblemModalOpened(
                        true,
                        NO_CATEGORIES_ENABLED_FOR_SALESMODE
                    );
                    setNavigationIndex(0);
                }
            })
            .catch((error) =>
                console.log(
                    `Error while getting updating catalog, with message: ${JSON.stringify(
                        error
                    )}`
                )
            );
    }

    React.useEffect(() => {
        const socketApi = middlewareApiUrl.split("/api")[0];
        const socket = io(socketApi, {
            query: {
                uuidConnectedEntity: shopDetail?.franchise_uid,
                shopUid: shopDetail?.shop_uid,
                IPaddress: machineIpAddress,
                kioskName: selectedShopApp.name,
            },
            forceNew: false,
            transports: ["polling", "websocket"],
            reconnection: true,
            reconnectionDelay: 10000, // defaults to 1000
            reconnectionDelayMax: 10000, // defaults to 5000

            reconnectionAttempts: Infinity,
            timeout: 30000, // Adjust connection timeout as needed
        });
        const username = `${shopDetail?.schema}|${shopDetail?.id}`;

        socket.on("connect", () => {
            const uidModification: ModificationUuidType = JSON.parse(
                localStorage.getItem("uidModification") as string
            );

            //! here verification of modification on first connection
            socket.emit("checkModification", {
                username,
                message: uidModification,
            });
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        socket.on(
            "openSession",
            // eslint-disable-next-line
            ({ uuidSession }: any) => {
                if (
                    (uuidSession === 0 || oldUuidSession === undefined) &&
                    process.env.REACT_APP_MODE !== CATALOGUE_MODE
                ) {
                    setIsLicenseProblem(true);
                    localStorage.removeItem("uuidSession");
                    console.log(
                        `license problem detected on connection with uuidSession: ${uuidSession}`
                    );
                    return;
                }
                setIsLicenseProblem(false);
                console.log(
                    `Successfully open license session on connection with uuidSession: ${uuidSession}`
                );
                localStorage.setItem("uuidSession", uuidSession);
            }
        );

        socket.on("Standby_Kiosk", ({ message, kioskName, kioskState }) => {
            const uidModification: ModificationUuidType = JSON.parse(
                localStorage.getItem("uidModification") as string
            );
            console.log(`Kioask application Paused event from Redis`);
            if (uidModification?.standByKiosk !== message) {
                localStorage.setItem(
                    "uidModification",
                    JSON.stringify({
                        ...uidModification,
                        standByKiosk: message,
                    })
                );

                if (
                    uidModification?.standByKiosk !== "" &&
                    (kioskName === selectedShopApp.name || kioskName === "all")
                ) {
                    console.log(
                        `Kioask application Paused event with "isAppPaused": ${kioskState}`
                    );
                    setIsAppPaused(kioskState);
                    if (ledConfig.isLedActive) {
                        if (kioskState) {
                            handleLedControl(
                                ledConfig.connectedLedPort,
                                "Orange"
                            );
                        } else {
                            handleLedControl(
                                ledConfig.connectedLedPort,
                                "Green"
                            );
                        }
                    }
                }
            }
        });

        socket.on(
            "ledAlert",
            ({ clientIpAdress, ledPort, isVisibiliteLedActive }) => {
                if (
                    clientIpAdress !== machineIpAddress ||
                    process.env.REACT_APP_MODE === CATALOGUE_MODE
                ) {
                    return;
                }

                console.log(
                    `Led configuration has been setted with typeLed : ${ledPort} and led visibility is ${isVisibiliteLedActive}`
                );
                if (isVisibiliteLedActive === false && ledConfig.isLedActive) {
                    handleLedControl(ledConfig.connectedLedPort, "Disable");
                    setIsLedActive(isVisibiliteLedActive as boolean);
                    setConnectedLedPort(ledPort as string);
                } else {
                    setIsLedActive(isVisibiliteLedActive as boolean);
                    setConnectedLedPort(ledPort as string);
                }
            }
        );

        socket.on("New_catalog", ({ message }: { message: string }) => {
            console.log(`New Card event detected with ID: ${socket.id}`);
            const uidModification: ModificationUuidType = JSON.parse(
                localStorage.getItem("uidModification") as string
            );

            if (uidModification?.catalog !== message) {
                localStorage.setItem(
                    "uidModification",
                    JSON.stringify({
                        ...uidModification,
                        catalog: message,
                    })
                );
                if (
                    ((project.template.pages.ways["salesMethods"] as PageType)
                        .skipped as boolean) === true
                ) {
                    const activeSalesMode = (
                        project.template.content
                            .salesMethods as ProjectContentItemType
                    ).items.filter((item) => item.active === true);
                    if (activeSalesMode.length === 1) {
                        setSelectedSaleModeOrderTaking(
                            activeSalesMode[0].settingKey as string
                        );
                    }
                }
                getCatalogueCarte();
            }
        });

        socket.on(
            "New_setting",
            ({ message }: { message: Record<string, string> }) => {
                console.log(`New Setting event detected with ID: ${socket.id}`);
                const modificationUuid: ModificationUuidType = JSON.parse(
                    localStorage.getItem("uidModification") as string
                );

                localStorage.setItem(
                    "uidModification",
                    JSON.stringify({
                        ...modificationUuid,
                        setting: message,
                    })
                );

                if (
                    modificationUuid.setting !==
                    (message as Record<string, string> | string)
                ) {
                    console.log(
                        `Setting: setIsProjectMustBeUpdated true to  be updated in standby screen page`
                    );
                    setIsProjectMustBeUpdated({
                        setting: true,
                        peripheral: false,
                    });
                }
            }
        );

        socket.on("New_peripheral", ({ message }: { message: string }) => {
            console.log(
                `New Peripheral event detected with ID: ${socket.id}, Message: ${message}`
            );
            const modificationUuid: ModificationUuidType = JSON.parse(
                localStorage.getItem("uidModification") as string
            );

            localStorage.setItem(
                "uidModification",
                JSON.stringify({
                    ...modificationUuid,
                    peripheral: message,
                })
            );

            if (modificationUuid.peripheral !== message) {
                console.log(
                    `Peripheral: setIsProjectMustBeUpdated true to  be updated in standby screen page`
                );
                setIsProjectMustBeUpdated({
                    setting: false,
                    peripheral: true,
                });
            }
        });
        socket.on(
            "resynchronise",
            ({ message, appId }: { message: string; appId: string }) => {
                console.log(
                    `Resynchronise Code event detected with ID: ${socket.id}, Message: ${message}`
                );
                const modificationUuid: ModificationUuidType = JSON.parse(
                    localStorage.getItem("uidModification") as string
                );

                localStorage.setItem(
                    "uidModification",
                    JSON.stringify({
                        ...modificationUuid,
                        appId: message,
                    })
                );
                if (selectedShopApp.appId === appId) {
                    setIsAppMustBeResynchronise(true);
                }
            }
        );
        socket.on(
            "pass_code",
            ({ message, passcode }: { message: string; passcode: string }) => {
                console.log(
                    `New Pass Code event detected with ID: ${socket.id}, Message: ${message}`
                );
                const modificationUuid: ModificationUuidType = JSON.parse(
                    localStorage.getItem("uidModification") as string
                );

                localStorage.setItem(
                    "uidModification",
                    JSON.stringify({
                        ...modificationUuid,
                        passCode: message,
                    })
                );

                if (modificationUuid.peripheral !== message) {
                    setSelectedShopApp({
                        ...selectedShopApp,
                        passcode,
                    });
                }
            }
        );

        socket.on("New_certificate", ({ message }: { message: string }) => {
            console.log(`New certificate event detected with ID: ${socket.id}`);
            const uidModification: ModificationUuidType = JSON.parse(
                localStorage.getItem("uidModification") as string
            );
            if (uidModification?.console === message) return;
            localStorage.setItem(
                "uidModification",
                JSON.stringify({
                    ...uidModification,
                    console: message,
                })
            );
            checkforLicenseAvailability();
        });

        socket.on("disconnect", (reason) => {
            if (reason === "io server disconnect") {
                console.log(
                    `socket disconnect due  server disconnect with reason: ${JSON.stringify(
                        reason
                    )}`
                );
                // disconnect initiated by server. Manually reconnect
                socket.connect();
            } else {
                console.log(
                    `License session closed due socket disconnection with reason: ${JSON.stringify(
                        reason
                    )}`
                );
                localStorage.removeItem("uuidSession");
            }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        socket.on("connect_error", (err: any) => {
            if (err.type === "TransportError") {
                // the Socket will automatically try to reconnect
            } else {
                socket.connect();
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        socket.on("out_of_stock", ({ message }: { message: any }) => {
            console.log(
                `New out of stock event detected with data message: ${JSON.stringify(
                    message
                )}`
            );
            dispatch(setOutOfStockItems(message));
        });

        if (posEditor === TCPOS) {
            connectWebSocket();
        }

        return () => {
            localStorage.removeItem("uuidSession");
            socket.disconnect();
            socket.off("New_cart");
            socket.off("New_setting");
            socket.off("openSession");
            socket.off("New_certificate");
        };
    }, []);
}
