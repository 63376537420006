import { CHANGE_MACHINE_ID } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";

import { formatCurrency } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function ChangeMachineCard(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        amountDeposited,
        customerLanguage,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        isOrderPartialPaidWithLoyaltyAccountBalance,
    } = useSnapshot(kioskStore);

    // eslint-disable-next-line
    const { totalPrice } = useSelector((state: any) => state.orderSlice.order);

    const payment = (content.payment as PaymentContentItemType).items.filter(
        (item: PaymentMainContentItemType) => item.id === CHANGE_MACHINE_ID
    )[0].languages[customerLanguage.name].amount;

    const unitPrice = isOrderPartialPaidWithLoyaltyAccountBalance
        ? totalOrderAfterPaidWithLoyaltyAccountBalance
        : totalPrice;

    const leftToPay = Math.max(0, unitPrice - amountDeposited);

    return (
        <Card
            style={{
                width: "910px",
                height: "265px",
                borderRadius: "31px",
                borderBottom: "12px solid #D51B1B",
                boxShadow: "0px 3px 6px #00000029",
                font: "Segoe UI",
            }}
        >
            <div
                className="d-grid__clz"
                style={{
                    gridTemplateRows:
                        payment.informationMessage?.isTextVisible &&
                        payment.informationMessage?.isTextVisible
                            ? "1fr 0.1fr 0.8fr 0.8fr"
                            : "1fr 1fr",
                    height: "100%",
                }}
            >
                {payment.informationMessage?.isTextVisible &&
                payment.informationMessage?.isTextVisible === true ? (
                    <>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                gap: "280px",
                                fontSize: "50px",
                            }}
                        >
                            <div>{payment.informationMessage.name}</div>
                            <div className="font-weight-bold">
                                {formatCurrency(leftToPay)}
                            </div>
                        </div>

                        <hr
                            style={{
                                height: "2px",
                                backgroundColor: "#AFAFAF",
                                border: "0px",
                                margin: "20px",
                            }}
                        />
                    </>
                ) : null}
                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-2"
                    style={{
                        fontSize: "45px",
                    }}
                >
                    <div>{payment.amountToBePaid.name}</div>
                    <div>{formatCurrency(amountDeposited)}</div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-4"
                    style={{
                        fontSize: "30px",
                        color: "#7C7C7C",
                    }}
                >
                    <div>{payment.totalAmount.name}</div>
                    <div>{formatCurrency(totalPrice)}</div>
                </div>
            </div>
        </Card>
    );
}
