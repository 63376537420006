/* eslint-disable @typescript-eslint/no-explicit-any */
import { TCPOS } from "@constants";
import { changeLanguage } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch } from "react-redux";

import { store } from "@store";

import { initConnectionCashMAchine } from "@helpers/cashmachine";
import { saveMiddlewareIpAddress } from "@helpers/fetcher";

import { LoadingModalWrapper } from "@components/LocalSettings/LocalConfigModalWrapper/Tcpos/LoadingModalWrapper";
import { ScanQrCodeModalWrapper } from "@components/LocalSettings/ScanQrCodeModalWrapper";
import { UsersListModalWrapper } from "@components/LocalSettings/UsersListModalWrapper";
import { clearTCPOSOrder } from "@components/OrderTaking/Helpers";
import {
    clearMiddlwareOrder,
    clearOrder,
} from "@components/OrderTaking/Store/feature";
import { EntitySelectionModalWrapper } from "@components/Synchronization/EntitySelectionModalWrapper";
import { syncStore } from "@components/Synchronization/store";

import { PausedAppSwiperWrapper } from "@pages/Kiosk/Steps/StandbyStep/PausedAppSwiperWrapper";
import { ProdAppSwiperWrapper } from "@pages/Kiosk/Steps/StandbyStep/ProdAppSwiperWrapper";
import {
    initStoreVariablesInStandbyScreenStep,
    kioskStore,
    setActiveStandbyScreenItems,
    setCustomerLanguage,
    setIsAuthenticationModalOpened,
    setIsOrderTakingPreviousStep,
    setStandbyScreenItems,
} from "@pages/Kiosk/store";

import "./index.css";

export function StandbyStep(): JSX.Element {
    const { posEditor, isAppPaused } = useSnapshot(store);
    const {
        isUsersListModalOpened,
        isScanQrCodeModalOpened,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
        isFenceProblemDetected,
        isLoadingModalOpened,
    } = useSnapshot(kioskStore);

    const { isEntitySelectionModalOpened, peripheralShopApp } =
        useSnapshot(syncStore);

    const isChangeMachineActive = (content.meansOfPayment as any).items.filter(
        (item: any) => item.name === "Change machine"
    )[0]?.active;

    const dispatch = useDispatch();

    const handleOnclickLocalAccess = () => {
        setIsAuthenticationModalOpened(true);
    };

    React.useEffect(() => {
        initStoreVariablesInStandbyScreenStep();
        setCustomerLanguage(
            (content.languages as LanguagesType).items.filter(
                (language) => language.isDefault === true
            )[0]
        );
        changeLanguage(
            (content.languages as LanguagesType).items.filter(
                (language) => language.isDefault === true
            )[0].name
        );

        if (TCPOS === posEditor) {
            dispatch(clearTCPOSOrder());
        } else {
            dispatch(clearOrder());
            dispatch(clearMiddlwareOrder());
        }

        setIsOrderTakingPreviousStep(false);

        const cashMachine: any = peripheralShopApp?.cash_managements;
        const cashMachineConfig: any = cashMachine
            ? Object.values(cashMachine)
            : [];

        if (
            isChangeMachineActive &&
            cashMachineConfig.length > 0 &&
            cashMachineConfig[0]?.mark === "Glory"
        ) {
            initConnectionCashMAchine();
        }

        // set middleware API address for ping
        const middlewareApiUrl = new URL(
            JSON.parse(localStorage.getItem("middlewareApiUrl") as string)
        ).hostname;

        saveMiddlewareIpAddress(middlewareApiUrl);
    }, []);

    React.useEffect(() => {
        setActiveStandbyScreenItems(
            (ways.standbyScreen as StandbyScreenPageType).items.filter(
                (item) => item.active === true
            )
        );
        setStandbyScreenItems(
            (
                (content.standbyStateScreen as StandbyStateScreenItemsType)
                    .items as StandbyScreenItemType[]
            ).filter((item) => item.active === true)
        );
    }, [content.standbyStateScreen, ways.standbyScreen]);

    return (
        <div>
            {isUsersListModalOpened === true ? <UsersListModalWrapper /> : null}
            {isScanQrCodeModalOpened === true ? (
                <ScanQrCodeModalWrapper />
            ) : null}
            {isEntitySelectionModalOpened === true ? (
                <EntitySelectionModalWrapper />
            ) : null}
            {isLoadingModalOpened === true ? <LoadingModalWrapper /> : null}
            {isAppPaused === true ? (
                <PausedAppSwiperWrapper />
            ) : (
                <ProdAppSwiperWrapper />
            )}
            <div
                style={{
                    position: "fixed",
                    zIndex: isFenceProblemDetected ? 3 : 2,
                    bottom: "0px",
                    height: "45px",
                    width: "45px",
                    cursor: "pointer",
                }}
                onClick={handleOnclickLocalAccess}
            />
        </div>
    );
}
