/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    AGENT_LOCAL_API_URL,
    BASIC_COMPOSITION_TYPE,
    CASHPAD,
    CATALOGUE_MODE,
    DEFAULT_COUNTRY_CODE_FR,
    GODIA_LOYALTY,
    KIOSK_AUTHORIZATION_ID,
    MAX_RETRY_ATTAINT,
    NETWORK_PROBLEM,
    Retrait_C_C,
    SEND_MAIL_WITH_SMALL_TICKET,
    SEND_NORMAL_TICKET,
    SEND_ONLY_SMALL_TICKET,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { Dispatch } from "@reduxjs/toolkit";
import { t } from "i18next";
import {
    CountryCode,
    isPossiblePhoneNumber,
    parsePhoneNumberFromString,
} from "libphonenumber-js";
import { cloneDeep } from "lodash";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import uuid from "uuid-by-string";
import { snapshot } from "valtio";
import * as Yup from "yup";

import i18n from "@i18n";

import { setIsTechProblemModalOpened, setPosEditor, store } from "@store";

import { useLocalWays } from "@hooks/useLocalWays";

import { refreshTcPosOrder } from "@components/OrderTaking/Helpers";
import { setOrderTakingSetting } from "@components/OrderTaking/Helpers/Commonfunction";
import { refreshDataNeedTcpos } from "@components/OrderTaking/Helpers/TCPOS";
import {
    addResetUserRewards,
    setSetting,
    setUserGifts,
} from "@components/OrderTaking/Store/feature";
import {
    setConnectedUser,
    setConnectedUserEntity,
    setIsEntitySelectionModalOpened,
    setIsSubRowEntityClicked,
    setLoginKeyboardInputsValues,
    setUidConnectedUser,
    syncStore,
} from "@components/Synchronization/store";

import {
    kioskStore,
    setActiveConfigOptions,
    setActiveLanguages,
    setAmountDeposited,
    setAuthModalStepIndex,
    setChosenInformationMode,
    setCustomerLanguage,
    setFinalMessageSelectedInformationMode,
    setFinalMessageSelectedRegulationMode,
    setInitialStepTimeout,
    setIsAccessNotAllowed,
    setIsAnotherPaymentModeButtonDisabled,
    setIsAuthenticationErrorModalOpened,
    setIsAuthenticationModalOpened,
    setIsCashTransactionValidated,
    setIsCommandInsertionProblemModalOpened,
    setIsConfigOptionsActive,
    setIsCreatingVoucherAccepted,
    setIsLanguagesActive,
    setIsLocalConfigModalOpened,
    setIsLocalSettingModalOpened,
    setIsOrderInsertedSuccessfully,
    setIsOrderInsertionRefused,
    setIsOrderPartialPaidWithLoyaltyAccountBalance,
    setIsOrderTakingPreviousStep,
    setIsRegulationModesModalButtonClicked,
    setIsRegulationModesModalOpened,
    setIsSelectedSaleModeWithoutInformationModes,
    setIsTransactionCanceled,
    setIsUserAccountModalOpened,
    setIsUserAuthenticated,
    setKeyboardInputsValues,
    setKioskWays,
    setLeftToPayAfterCashMachineValidation,
    setLogo,
    setNavigationIndex,
    setNumericKeyboardInputValue,
    setOrderModificationMode,
    setOrderNumber,
    setPaymentModes,
    setPaymentStepCounter,
    setProject,
    setProjectTimeout,
    setQrCodeValue,
    setSelectedAuthMode,
    setSelectedInformationMode,
    setSelectedRegulationModeValue,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
    setUser,
} from "@pages/Kiosk/store";
import {
    setCreditCardPaymentChoice,
    setIsBackButtonDisabled,
    setIsButtonClickedOnCBPaymentMode,
    setIsCustomerBenefitOpen,
    setLocalProject,
    setPagesItems,
} from "@pages/Kiosk/store/actions";

export function groupItemByLanguage(
    // eslint-disable-next-line
    items: any[],
    property: string
    // eslint-disable-next-line
): any[] {
    // eslint-disable-next-line
    return items.reduce(function (acc: any, obj: any) {
        if (!acc[property]) {
            acc[property] = [];
        }
        acc[property].push({
            ...obj.languages[property],
            id: obj.id,
            name: obj.name,
        });
        return acc;
    }, {})[property];
}

// @see https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
export function capitalize(s: string): string {
    return s?.charAt(0).toUpperCase() + s?.slice(1);
}

export function getImageContentById(id: string): string {
    const {
        project: { files: images },
        customerLanguage,
    } = snapshot(kioskStore);
    let imageContent = "";

    if (images[id] !== undefined) {
        if (typeof images[id]?.content === "string") {
            imageContent = images[id]?.content as string;
        } else {
            // eslint-disable-next-line
            imageContent = (images[id]?.content as ImageItemLanguagesType)[
                customerLanguage.name
            ].path;
        }
    }
    if (imageContent === "") {
        return "";
    }
    return VALIDATED_EXTENSION_FILE.includes(imageContent.substr(-4))
        ? imageContent?.includes("http")
            ? getFilenameFromUrl(imageContent, "template")
            : `./images/${imageContent}`
        : imageContent;
}

// eslint-disable-next-line
export function moveArrayElementToTheEnd(element: any, array: any[]) {
    const itemPositionInArray = array.findIndex((el) => el.id === element.id);

    return [
        ...array.slice(0, itemPositionInArray),
        ...array.slice(itemPositionInArray + 1, array.length),
        element,
    ];
}

// eslint-disable-next-line
export function moveArrayElementToTheBegin(element: any, array: any[]) {
    const itemPositionInArray = array.findIndex((el) => el.id === element.id);

    return [
        element,
        ...array.slice(0, itemPositionInArray),
        ...array.slice(itemPositionInArray + 1, array.length),
    ];
}

// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
export function formatCurrency(currencyData: number): string {
    const { devise } = snapshot(kioskStore);
    return (
        currencyData.toFixed(devise.decimalPrice) + ` ${devise.deviseSymbole}`
    );
}

export function changeLanguage(language: string): void {
    i18n.changeLanguage(language);
}

export function getBackgroundImagePath(
    images: ImageProjectItemType,
    kioskWays: WaysType[],
    navigationIndex: number
): string {
    const backgroundImageId = Object.values(images).find((image) => {
        return (
            image.key === "background" &&
            image.name === kioskWays[navigationIndex].name
        );
    })?.id;

    const imageSrc = getImageContentById(backgroundImageId as string);

    return imageSrc;
}

function initVariablesInPaymentStep() {
    const {
        isRegulationModeModalButtonClicked,
        isAnotherPaymentModeButtonDisabled,
        isCashTransactionValidated,
        isTransactionCanceled,
        leftToPayAfterCashMachineValidation,
        isRegulationModesModalOpened,
        selectedRegulationModeValue,
        project: {
            template: { content },
        },
        customerLanguage,
    } = snapshot(kioskStore);

    const { peripheralShopApp } = snapshot(syncStore);

    const isTpaValinaShowModal = (
        content.payment as PaymentContentItemType
    ).items.filter((item) => item.name === "credit card")[0]?.languages[
        customerLanguage.name
    ].paymentInstructions.TPAValina?.active as boolean;
    if (Object.keys((peripheralShopApp?.monetics as any) || {}).length > 0) {
        const { mark } = Object.values(
            peripheralShopApp?.monetics as any
        )[0] as any;

        if (isTpaValinaShowModal && mark === "Valina") {
            setCreditCardPaymentChoice("");
        }
    }

    if (isRegulationModesModalOpened) {
        setIsRegulationModesModalOpened(false);
    }
    if (isRegulationModeModalButtonClicked) {
        setIsRegulationModesModalButtonClicked(false);
    }
    if (isAnotherPaymentModeButtonDisabled === false) {
        setIsAnotherPaymentModeButtonDisabled(true);
    }

    console.log({ selectedRegulationModeValue }, "in paymentStep");

    if (isCashTransactionValidated === true) {
        setIsCashTransactionValidated(false);
    }
    if (isTransactionCanceled === true) {
        setIsTransactionCanceled(false);
    }

    if (leftToPayAfterCashMachineValidation !== 0) {
        setLeftToPayAfterCashMachineValidation(0);
    }
    setPaymentStepCounter(0);
}

export async function getSkippedStepNumber(
    kioskWays: WaysType[],
    navigationIndex: number,
    isAuthenticationAccepted: boolean,
    isUserAuthenticated: boolean,
    selectedSaleModeValue: string,
    selectedInformationMode: string,
    isOrderPaidWithLoyaltyAccountBalance: boolean,
    selectedRegulationModeValue: string,
    content: ProjectContentType,
    totalPrice: number,
    selectedAuthMode?: string
): Promise<number | undefined> {
    const {
        isSelectedSaleModeWithoutInformationModes,
        leftToPay,
        isClickAndCollectOrderPaid,
        userAuthInfo,
        paymentModes,
        isClickAndCollect,
        clickAndCollectOrderSalesMode,
        isInformationModeActivated,
    } = snapshot(kioskStore);
    const { peripheralShopApp } = snapshot(syncStore);
    const timestamp = new Date().getTime();
    let localCounter = 1;
    let localSelectedInformationMode = "";
    let localSelectedRegulationMode = "";
    let localSelectedAuthMode = "";
    let localSelectedSaleModeValue = isClickAndCollect
        ? clickAndCollectOrderSalesMode
        : selectedSaleModeValue;
    const salesMethodsStepIndex = kioskWays.indexOf(
        kioskWays.filter((page) => page?.name === "salesMethods")[0]
    );
    const connectionStepIndex = kioskWays.findIndex(
        (page) => page?.name === "connection"
    );
    const { middlewareApiUrl } = snapshot(store);
    for (let i = 1; i < kioskWays.length - navigationIndex; i++) {
        let flag = false;
        if (
            ((kioskWays[navigationIndex + i] as PageType).skipped as boolean) &&
            ["authenticationModes", "salesMethods", "meansOfPayment"].includes(
                kioskWays[navigationIndex + i].name
            )
        ) {
            switch (kioskWays[navigationIndex + i].name) {
                case "authenticationModes":
                    if (!isClickAndCollect) {
                        if (
                            isAuthenticationAccepted ||
                            connectionStepIndex === -1
                        ) {
                            const activeAuthModeName = (
                                content.authenticationModes as ProjectContentItemType
                            ).items.filter((item) => item.active === true)[0]
                                .shortName;
                            setSelectedAuthMode(activeAuthModeName as string);
                            localSelectedAuthMode =
                                activeAuthModeName as string;
                        }
                    }
                    localCounter += 1;
                    break;
                case "salesMethods":
                    {
                        const activeSalesMode = (
                            content.salesMethods as ProjectContentItemType
                        ).items.filter((item) => item.active === true)[0];

                        const isLocalSelectedSaleModeWithoutInformationModes =
                            (
                                content.salesMethods as ProjectContentItemType
                            ).items.filter(
                                (option: ProjectMainContentItemType) => {
                                    return (
                                        option.name ===
                                        (isClickAndCollect
                                            ? clickAndCollectOrderSalesMode
                                            : activeSalesMode.name)
                                    );
                                }
                            )[0]?.informationModes?.items.length === 0 ||
                            (
                                content.informationModes as ProjectContentItemType
                            ).items.filter(
                                (option: ProjectMainContentItemType) => {
                                    return (
                                        (option.active as ItemContentType)[
                                            isClickAndCollect
                                                ? clickAndCollectOrderSalesMode
                                                : activeSalesMode.name
                                        ] === true
                                    );
                                }
                            ).length === 0;
                        setIsSelectedSaleModeWithoutInformationModes(
                            isLocalSelectedSaleModeWithoutInformationModes
                        );
                        setSelectedSaleModeValue(
                            activeSalesMode.name as string
                        );
                        setSelectedSaleModeOrderTaking(
                            activeSalesMode.settingKey as string
                        );
                        setSelectedSaleModeId(activeSalesMode.id as string);
                        localSelectedSaleModeValue =
                            activeSalesMode.name as string;
                        localCounter += 1;
                    }
                    break;
                case "meansOfPayment":
                    {
                        let activeRegulationsModes = (
                            content.meansOfPayment as ProjectContentItemType
                        ).items.filter((item) => item.active === true);

                        if (
                            activeRegulationsModes.length > 1 &&
                            Object.keys(
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                (peripheralShopApp?.monetics as any) || {}
                            ).length === 0
                        ) {
                            activeRegulationsModes =
                                activeRegulationsModes?.filter(
                                    (item) =>
                                        item.active === true &&
                                        item.shortName !== "creditCard"
                                );
                        }
                        setFinalMessageSelectedRegulationMode(
                            activeRegulationsModes[0].name
                        );
                        setSelectedRegulationModeValue(
                            activeRegulationsModes[0].shortName as string
                        );
                        localSelectedRegulationMode = activeRegulationsModes[0]
                            .shortName as string;
                        localCounter += 1;
                    }
                    break;
            }
        } else if (
            kioskWays[navigationIndex + i].name === "informationModes" &&
            ((
                (kioskWays[navigationIndex + i] as PageType)
                    ?.skipped as ItemContentType
            )[
                isClickAndCollect
                    ? clickAndCollectOrderSalesMode
                    : selectedSaleModeValue
            ] ||
                (
                    (kioskWays[navigationIndex + i] as PageType)
                        .skipped as ItemContentType
                )[localSelectedSaleModeValue])
        ) {
            const isLocalSelectedSaleModeWithoutInformationModes =
                (content.salesMethods as ProjectContentItemType).items.filter(
                    (option: ProjectMainContentItemType) => {
                        return (
                            option.name ===
                            (isClickAndCollect
                                ? clickAndCollectOrderSalesMode
                                : localSelectedSaleModeValue)
                        );
                    }
                )[0]?.informationModes?.items.length === 0 ||
                (
                    content.informationModes as ProjectContentItemType
                ).items.filter((option: ProjectMainContentItemType) => {
                    return (
                        (option.active as ItemContentType)[
                            isClickAndCollect
                                ? clickAndCollectOrderSalesMode
                                : localSelectedSaleModeValue
                        ] === true
                    );
                }).length === 0;

            if (isLocalSelectedSaleModeWithoutInformationModes === false) {
                const activeInformationMode = (
                    content.informationModes as ProjectContentItemType
                ).items
                    .map((datum: ProjectMainContentItemType) => {
                        if (
                            (datum.active as ItemContentType)[
                                isClickAndCollect
                                    ? clickAndCollectOrderSalesMode
                                    : localSelectedSaleModeValue
                            ] === true
                        ) {
                            return datum;
                        }
                    })
                    .filter((element) => element !== undefined)[0];

                if (activeInformationMode !== undefined) {
                    localSelectedInformationMode = (
                        activeInformationMode as ProjectMainContentItemType
                    ).shortName as string;

                    setSelectedInformationMode(
                        (activeInformationMode as ProjectMainContentItemType)
                            .shortName as string
                    );
                    setFinalMessageSelectedInformationMode(
                        (activeInformationMode as ProjectMainContentItemType)
                            .prettyName as string
                    );
                }
            } else {
                setSelectedInformationMode("");
                setFinalMessageSelectedInformationMode("none");
            }
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "informationModes" &&
            (salesMethodsStepIndex === -1 ||
                isSelectedSaleModeWithoutInformationModes === true ||
                isInformationModeActivated === false)
        ) {
            localSelectedInformationMode = "";
            setSelectedInformationMode("");
            setFinalMessageSelectedInformationMode("none");
            localCounter += 1;
        } else if (
            (kioskWays[navigationIndex + i].name ===
                "informationModesConsommation" &&
                ((selectedInformationMode === "" &&
                    localSelectedInformationMode === "") ||
                    isSelectedSaleModeWithoutInformationModes === true ||
                    isInformationModeActivated === false)) ||
            (kioskWays[navigationIndex + i].name === "payment" &&
                (["atTheCounter", "BDP"].includes(
                    selectedRegulationModeValue
                ) ||
                    ["atTheCounter", "BDP"].includes(
                        localSelectedRegulationMode
                    ) ||
                    (selectedRegulationModeValue === "" &&
                        localSelectedRegulationMode === "" &&
                        kioskWays[navigationIndex].name !== "meansOfPayment") ||
                    isOrderPaidWithLoyaltyAccountBalance === true ||
                    leftToPay === 0))
        ) {
            localCounter += 1;
        } else if (
            (kioskWays[navigationIndex + i].name === "authenticationModes" &&
                ((!isAuthenticationAccepted && connectionStepIndex > -1) ||
                    isUserAuthenticated)) ||
            (kioskWays[navigationIndex + i].name === "meansOfPayment" &&
                (isOrderPaidWithLoyaltyAccountBalance === true ||
                    isClickAndCollectOrderPaid))
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name ===
                "authenticationModeConsommation" &&
            (!isAuthenticationAccepted ||
                isUserAuthenticated ||
                (isAuthenticationAccepted && !isUserAuthenticated)) &&
            localSelectedAuthMode === "" &&
            (selectedAuthMode === "" || selectedAuthMode === undefined)
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "clickAndCollect" &&
            selectedSaleModeValue !== "Retrait C&C" &&
            localSelectedSaleModeValue !== "Retrait C&C"
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "connection" &&
            isUserAuthenticated
        ) {
            localCounter += 1;
        } else if (
            kioskWays[navigationIndex + i].name === "loyaltyConsommation"
        ) {
            const totalPaymentAmount = paymentModes.reduce(
                (acc: number, curr) => acc + curr.paymentAmount,
                0
            );
            const isOrderTotallyPaid = totalPaymentAmount === totalPrice;
            console.log(
                JSON.stringify({ isOrderTotallyPaid, isUserAuthenticated })
            );

            if (isUserAuthenticated === false || isOrderTotallyPaid) {
                localCounter += 1;
                setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
            } else {
                try {
                    const loyaltyBalanceResult = await fetch(
                        `${middlewareApiUrl}/loyaltyBalance?timestamp=${timestamp}`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(userAuthInfo),
                        }
                    ).then((response) => response.json());

                    console.log(
                        `loyaltyBalance request result is ${JSON.stringify(
                            loyaltyBalanceResult
                        )}`
                    );
                    // eslint-disable-next-line
                    if ((loyaltyBalanceResult as any).code === 2) {
                        if (
                            // eslint-disable-next-line
                            (loyaltyBalanceResult as any).response
                                .loyaltyAccount <= 0
                        ) {
                            localCounter += 1;
                            setIsOrderPartialPaidWithLoyaltyAccountBalance(
                                false
                            );
                        } else {
                            // eslint-disable-next-line
                            setUser((loyaltyBalanceResult as any).response);
                            flag = true;
                            return localCounter;
                        }
                    } else {
                        localCounter += 1;
                        setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
                    }
                } catch (error: unknown) {
                    localCounter += 1;
                    setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
                    console.error(
                        `Error while getting user information, with message: ${
                            (error as Error).message
                        }`
                    );
                }
            }
        } else {
            flag = true;
            if (kioskWays[navigationIndex + localCounter].name === "payment") {
                initVariablesInPaymentStep();
            }
            return localCounter;
        }

        if (flag) {
            break;
        }
    }
}

export async function getPreviousSkippedStepNumber(
    kioskWays: WaysType[],
    navigationIndex: number,
    isAuthenticationAccepted: boolean,
    isUserAuthenticated: boolean,
    selectedSaleModeValue: string,
    selectedInformationMode: string,
    isOrderPaidWithLoyaltyAccountBalance: boolean,
    selectedRegulationModeValue: string
): Promise<number | undefined> {
    let localCounter = 1;
    const salesMethodsStepIndex = kioskWays.indexOf(
        kioskWays.filter((page) => page?.name === "salesMethods")[0]
    );
    const {
        isSelectedSaleModeWithoutInformationModes,
        leftToPay,
        userAuthInfo,
        authModalStepIndex,
        isClickAndCollect,
        clickAndCollectOrderSalesMode,
        isInformationModeActivated,
    } = snapshot(kioskStore);
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);
    const connectionStepIndex = kioskWays.findIndex(
        (page) => page?.name === "connection"
    );
    for (let i = 1; i <= navigationIndex; i++) {
        let flag = false;
        if (
            // eslint-disable-next-line
            (((kioskWays[navigationIndex - i] as PageType)
                .skipped! as boolean) &&
                [
                    "authenticationModes",
                    "salesMethods",
                    "meansOfPayment",
                ].includes(kioskWays[navigationIndex - i].name)) ||
            (kioskWays[navigationIndex - i].name === "informationModes" &&
                (
                    (kioskWays[navigationIndex - i] as PageType)
                        .skipped as ItemContentType
                )[
                    isClickAndCollect
                        ? clickAndCollectOrderSalesMode
                        : selectedSaleModeValue
                ]) ||
            (kioskWays[navigationIndex - i].name === "clickAndCollect" &&
                selectedSaleModeValue !== Retrait_C_C) ||
            (kioskWays[navigationIndex - i].name ===
                "informationModesConsommation" &&
                (selectedInformationMode === "" ||
                    isSelectedSaleModeWithoutInformationModes === true ||
                    isInformationModeActivated === false)) ||
            (kioskWays[navigationIndex - i].name === "payment" &&
                (["atTheCounter", "BDP"].includes(
                    selectedRegulationModeValue
                ) ||
                    (selectedRegulationModeValue === "" &&
                        kioskWays[navigationIndex].name !== "meansOfPayment") ||
                    isOrderPaidWithLoyaltyAccountBalance === true ||
                    leftToPay === 0)) ||
            kioskWays[navigationIndex - i].name ===
                "authenticationModeConsommation" ||
            (kioskWays[navigationIndex - i].name === "authenticationModes" &&
                !isAuthenticationAccepted &&
                connectionStepIndex > -1) ||
            (kioskWays[navigationIndex - i].name === "informationModes" &&
                (salesMethodsStepIndex === -1 ||
                    isSelectedSaleModeWithoutInformationModes === true ||
                    isInformationModeActivated === false)) ||
            (kioskWays[navigationIndex - i].name === "connection" &&
                isUserAuthenticated) ||
            (kioskWays[navigationIndex - i].name === "meansOfPayment" &&
                isOrderPaidWithLoyaltyAccountBalance === true)
        ) {
            localCounter += 1;
        } else if (kioskWays[navigationIndex - i].name === "orderTaking") {
            setIsOrderTakingPreviousStep(true);
            flag = true;
            return localCounter;
        } else if (
            kioskWays[navigationIndex - i].name === "loyaltyConsommation"
        ) {
            if (isUserAuthenticated === false) {
                localCounter += 1;
                setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
            } else {
                try {
                    const loyaltyBalanceResult = await fetch(
                        `${middlewareApiUrl}/loyaltyBalance?timestamp=${timestamp}`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(userAuthInfo),
                        }
                    ).then((response) => response.json());

                    console.log(
                        `loyaltyBalance request result is ${JSON.stringify(
                            loyaltyBalanceResult
                        )}`
                    );
                    // eslint-disable-next-line
                    if ((loyaltyBalanceResult as any).code === 2) {
                        if (
                            // eslint-disable-next-line
                            (loyaltyBalanceResult as any).response
                                .loyaltyAccount <= 0
                        ) {
                            localCounter += 1;
                            setIsOrderPartialPaidWithLoyaltyAccountBalance(
                                false
                            );
                        } else {
                            // eslint-disable-next-line
                            setUser((loyaltyBalanceResult as any).response);
                            flag = true;
                            return localCounter;
                        }
                    } else {
                        localCounter += 1;
                        setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
                    }
                } catch (error: unknown) {
                    localCounter += 1;
                    setIsOrderPartialPaidWithLoyaltyAccountBalance(false);
                    console.error(
                        `Error while getting user information, with message: ${
                            (error as Error).message
                        }`
                    );
                }
            }
        } else {
            flag = true;
            if (kioskWays[navigationIndex - localCounter].name === "payment") {
                initVariablesInPaymentStep();
            }

            if (
                kioskWays[navigationIndex - localCounter].name ===
                    "authenticationModes" &&
                authModalStepIndex === 0 &&
                isAuthenticationAccepted
            ) {
                setIsUserAuthenticated(false);
            }
            return localCounter;
        }

        if (flag) {
            break;
        }
    }
}

export function truncateString(string: string, maxLength: number): string {
    return string.length > maxLength ? `${string.slice(0, maxLength)}` : string;
}

export function getSubStepBackgroundImagePath(
    images: ImageProjectItemType,
    selectedMode: string
): string {
    const backgroundImageId = Object.values(images).find((image) => {
        return image.key === "background" && image.subName === selectedMode;
    })?.id;
    return getImageContentById(backgroundImageId as string);
}

export function getLogoImagePath(images: ImageProjectItemType): string {
    const logoId = Object.values(images).find((image) => {
        return image.key === "Logo";
    })?.id;
    return getImageContentById(logoId as string);
}

export function getTopBannerImagePath(
    activePageName: string,
    images: ImageProjectItemType
): string {
    const topBannerBackgroundImageId = Object.values(images).find((image) => {
        return (
            image.key === "topBannerBackground" && image.name === activePageName
        );
    })?.id;
    return getImageContentById(topBannerBackgroundImageId as string);
}

export function handleAbortCBTransaction(): void {
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);
    const { peripheralShopApp } = snapshot(syncStore);
    const monetics: any = peripheralShopApp?.monetics;
    const moneticsConfig: any = monetics ? Object.values(monetics) : [];
    const mark = moneticsConfig[0]?.mark;

    let url = "";

    if (mark === "Valina") {
        url = `${AGENT_LOCAL_API_URL}/AbortValina?timestamp=${timestamp}`;
    } else {
        url = `${middlewareApiUrl}/AbortCBTransaction?timestamp=${timestamp}`;
    }
    console.log(
        "handleAbortCBTransaction function, the launched API is",
        `${url}`
    );
    setIsBackButtonDisabled(true);
    setIsAnotherPaymentModeButtonDisabled(true);
    setIsTransactionCanceled(true);
    setIsButtonClickedOnCBPaymentMode({
        isAnotherPaymentModeButtonClicked: true,
        isBackPaymentModeButtonClicked: false,
    });
    fetch(url)
        .then((response) => response.json())
        .then((response) => {
            console.log(
                `the CB transaction is canceled by clicking another payment mode button in payment step with response: ${JSON.stringify(
                    response
                )}`
            );
        })
        .catch((error) => {
            console.log(
                `Error while cancelling the CB transaction, with the message: ${error}`
            );
        });
}

export function handleAbortCashMachineTransaction(): void {
    const { peripheralShopApp, machineIpAddress, selectedShopApp } =
        snapshot(syncStore);
    const { cashDroTransactionId } = snapshot(kioskStore);
    const timestamp = new Date().getTime();
    const cashMachine: any = peripheralShopApp?.cash_managements;
    const cashMachineConfig: any = cashMachine
        ? Object.values(cashMachine)
        : [];
    const cashMachineIpAddress = cashMachineConfig[0]?.ip;
    const cashMachineApiUrl = cashMachineConfig[0]?.cashMachineApiUrl;
    const cashMachineMark = cashMachineConfig[0]?.mark;
    const userCashDro = cashMachineConfig[0]?.user;
    const passCashDro = cashMachineConfig[0]?.password;

    let url;
    let bodyPaymentData;
    if (cashMachineMark === "Cashdro") {
        url = `http://${cashMachineApiUrl}:5011/cancelCashdro?timestamp=${timestamp}`;
        bodyPaymentData = {
            ipMonnayeur: cashMachineIpAddress,
            transactionId: cashDroTransactionId,
            userCashDro,
            passCashDro,
        };
    } else {
        url = `http://${cashMachineApiUrl}:5011/cancelOperation?timestamp=${timestamp}`;
        bodyPaymentData = {
            cashMachineIpAddress,
            clientIP: machineIpAddress,
            userId: selectedShopApp.name,
            userPwd: selectedShopApp.passcode,
        };
    }
    console.log(
        "handleAbortCashMachineTransaction function, the launched API is",
        { url }
    );

    setIsBackButtonDisabled(true);
    setIsAnotherPaymentModeButtonDisabled(true);
    setIsTransactionCanceled(true);
    setIsButtonClickedOnCBPaymentMode({
        isAnotherPaymentModeButtonClicked: true,
        isBackPaymentModeButtonClicked: false,
    });

    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyPaymentData),
    })
        .then((response) => response.json())
        .then((response) => {
            console.log(
                "the response of aborting cash machine transaction",
                JSON.stringify(response)
            );
            if (response.code === 2) {
                console.log(
                    "the cash machine transaction is canceled, we pass the amount deposited variable to 0 and the is transaction canceled tu true"
                );
                setAmountDeposited(0);
                setIsTransactionCanceled(true);
            }
        })
        .catch((error) => {
            console.log(
                `Error while cancelling the change machine transaction, with the message: ${error}`
            );
        });
}

export function generateItemPriceUuid(strings: string[]): string {
    const lowercasedStrings = strings?.map((element) => element.toLowerCase());
    const sorted = [...lowercasedStrings].sort((a, b) => a.localeCompare(b));
    const sortedStrings = sorted.join("");
    const sortedLetters = sortedStrings.split("").sort().join("");
    return uuid(sortedLetters + sortedStrings);
}

export async function handleUserAuthentication(
    authModeInfo: AuthModeInfoType,
    kioskWays: WaysType[],
    navigationIndex: number,
    isAuthenticationAccepted: boolean,
    selectedSaleModeValue: string,
    selectedInformationMode: string,
    isOrderPaidWithLoyaltyAccountBalance: boolean,
    selectedRegulationModeValue: string,
    authModalStepIndex: number,
    isAuthenticationErrorModalOpened: boolean,
    content: ProjectContentType,
    totalPrice: number,
    dispatch: Dispatch<any>,
    // eslint-disable-next-line
    clientInfo?: any
): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);
    const {
        saleMode,
        localSelectedInformationMode,
        localFinalMessageSelectedInformationMode,
        chosenLocalInformationMode,
        isOrderTakingPreviousStep,
        isClickAndCollect,
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: {
                                gifts: { active: giftsActive },
                                rewards: { active: rewardsActive },
                            },
                        },
                    },
                },
            },
        },
    } = snapshot(kioskStore);
    const { selectedShopApp } = snapshot(syncStore);
    const timestamp = new Date().getTime();
    console.log(
        "handleUserAuthentication function with auth mode info structure:",
        JSON.stringify(authModeInfo)
    );

    await fetch(`${middlewareApiUrl}/loyaltyBalance?timestamp=${timestamp}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(authModeInfo),
    })
        .then((response) => response.json())
        .then(async (result) => {
            console.log(
                `Result of getting user information: ${JSON.stringify(result)}`
            );

            if (result.code === 3) {
                setQrCodeValue("");
                if (isClickAndCollect) {
                    setIsUserAuthenticated(true);
                    setAuthModalStepIndex(0);
                    setSelectedAuthMode("");
                    setUser({
                        ...clientInfo,
                        loyaltyAccount: 0,
                        loyaltyAmount: 0,
                        loyaltyType: "",
                    });
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    true,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(totalPrice)
                                )
                            )
                    );
                } else {
                    setIsAuthenticationErrorModalOpened(
                        !isAuthenticationErrorModalOpened
                    );
                }
            } else {
                setUser(result.response);

                if (
                    selectedShopApp.fidelityType === GODIA_LOYALTY &&
                    (giftsActive || rewardsActive)
                ) {
                    setIsCustomerBenefitOpen(true);
                    dispatch(
                        addResetUserRewards({
                            rewards: result.response.rewards,
                            loyaltyAccount: result.response.loyaltyAccount,
                        })
                    );
                    dispatch(setUserGifts(result.response.promo));
                }

                if (authModalStepIndex !== 0) {
                    setNavigationIndex(authModalStepIndex);
                } else if (
                    selectedSaleModeValue === "Delivery" ||
                    saleMode.name === "Delivery"
                ) {
                    if (isOrderTakingPreviousStep === false) {
                        const salesMethodsStepIndex = kioskWays.indexOf(
                            kioskWays.filter(
                                (page) => page?.name === "salesMethods"
                            )[0]
                        );
                        setNavigationIndex(
                            salesMethodsStepIndex +
                                Number(
                                    await getSkippedStepNumber(
                                        kioskWays,
                                        salesMethodsStepIndex,
                                        isAuthenticationAccepted,
                                        true,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue,
                                        content,
                                        Number(totalPrice)
                                    )
                                )
                        );
                    } else {
                        setIsOrderTakingPreviousStep(true);
                        const orderTakingStepIndex = kioskWays.indexOf(
                            kioskWays.filter(
                                (page) => page?.name === "orderTaking"
                            )[0]
                        );
                        setNavigationIndex(orderTakingStepIndex);
                        setSelectedSaleModeValue(saleMode.name);
                        setSelectedSaleModeId(saleMode.id);
                        setSelectedSaleModeOrderTaking(saleMode.settingKey);
                        setOrderModificationMode();
                        setSelectedInformationMode(
                            localSelectedInformationMode
                        );
                        setFinalMessageSelectedInformationMode(
                            localFinalMessageSelectedInformationMode
                        );
                        setChosenInformationMode(chosenLocalInformationMode);
                        if (localSelectedInformationMode === "") {
                            setFinalMessageSelectedInformationMode("none");
                        }
                    }
                } else {
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    true,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(totalPrice)
                                )
                            )
                    );
                }
                setIsUserAccountModalOpened(false);
                setAuthModalStepIndex(0);
                setSelectedAuthMode("");
                setIsUserAuthenticated(true);
                setNumericKeyboardInputValue("");
                setKeyboardInputsValues({});
                setQrCodeValue("");
            }
        })
        .catch((error) =>
            console.error(
                `Error while getting user information, with message: ${error}`
            )
        );
}

function handlePrintVoucher(voucherId: string): void {
    const { middlewareApiUrl } = snapshot(store);
    const timestamp = new Date().getTime();
    fetch(
        `${middlewareApiUrl}/PrintBonAvoir?IdAvoir=${voucherId}&timestamp=${timestamp}`
    )
        .then((response) => response.json())
        .then((result) => {
            console.log(
                "The result of printing voucher",
                JSON.stringify(result)
            );
        })
        .catch((error) =>
            console.error(
                `Error while printing voucher, with the message:${error}`
            )
        );
}

export async function handlePutVoucher(voucher: VoucherType): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);
    const timestamp = new Date().getTime();
    const apiUrl = `${middlewareApiUrl}/PutBonAvoir?timestamp=${timestamp}`;
    console.log(
        "handlePutVoucher",
        "The voucher structure:",
        JSON.stringify(voucher)
    );
    mutate(
        apiUrl,
        await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(voucher),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(
                    "the response of inserting the voucher is:",
                    JSON.stringify(result)
                );
                if (result.code === 2) {
                    handlePrintVoucher(result.id_avoir);
                }
            })
            .catch((error) =>
                console.error(
                    `Error while inserting the voucher, with the message:${error}`
                )
            )
    );
}

export function handlePrintClientTicket(orderNumber: number): void {
    const timestamp = new Date().getTime();
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), 60000);
    const { middlewareApiUrl } = snapshot(store);
    const { emailTicket, isSendMailWithPdfActive, isPrintTicket } =
        snapshot(kioskStore);
    console.log("handlePrintClientTicket func is called after order insertion");
    const ticketType = isSendMailWithPdfActive
        ? SEND_MAIL_WITH_SMALL_TICKET
        : isPrintTicket === false
        ? SEND_ONLY_SMALL_TICKET
        : SEND_NORMAL_TICKET;
    const url =
        ticketType === 1
            ? `${middlewareApiUrl}/printTicket?numTicket=${orderNumber}&clientMail=${emailTicket}&sendMailWithPdf=${ticketType}&timestamp=${timestamp}`
            : `${middlewareApiUrl}/printTicket?numTicket=${orderNumber}&sendMailWithPdf=${ticketType}&timestamp=${timestamp}`;
    fetch(url, { headers: { "Cache-Control": "no-cache" }, signal })
        .then((response) => response.json())
        .then((result) => {
            console.log(
                "The result of printing client ticket",
                JSON.stringify(result)
            );
        })
        .catch((error) =>
            console.error(
                `Error while printing client ticket, with the message:${JSON.stringify(
                    error
                )}`
            )
        )
        .finally(() => clearTimeout(timeoutId));
}

async function missedTransactionAgentLocal(missedTransactionObject: any) {
    const timestamp = new Date().getTime();
    const {
        selectedRegulationModeValue,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        orderTotalPrice,
    } = snapshot(kioskStore);
    const { peripheralShopApp } = snapshot(syncStore);
    const printers: any = Object.values(
        (peripheralShopApp?.printers as any) ?? {}
    );
    const printerIp = printers[0]?.ip ?? "";
    const printerPath = printers[0]?.path ?? "";

    const printerConf = printerIp.length === 0 ? printerPath : printerIp;
    const paymentMethod =
        selectedRegulationModeValue === "creditCard" ? "TPA" : "ESP";

    const totalPrice = isOrderPartialPaidWithLoyaltyAccountBalance
        ? totalOrderAfterPaidWithLoyaltyAccountBalance
        : orderTotalPrice;
    await fetch(
        `${AGENT_LOCAL_API_URL}/missedTransaction?paymentMethod=${paymentMethod}&totalPrice=${totalPrice}&printerConf=${printerConf}&timestamp=${timestamp}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(missedTransactionObject),
        }
    )
        .then((response) => response.json())
        .then((result) => {
            if (!result.error) {
                console.log(
                    "missedTransactionAgentLocal function is succeeded with return msg:",
                    result.msg
                );
            } else {
                console.log(
                    "missedTransactionAgentLocal function is failed with return msg:",
                    result.msg
                );
            }
        })
        .catch((error) => {
            console.log(
                "error in missed transaction agent local function",
                error
            );
        });
}

export async function handlePutCommand(
    middlwareOrder: ObjectType
): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);
    const controller = new AbortController();
    const uuidConnectedEntity = localStorage.getItem("connectedUser") as string;

    console.log(
        "Put command function",
        "The order structure:",
        JSON.stringify(middlwareOrder)
    );

    const contentOfMissedTransaction = {
        url: `${middlewareApiUrl}/PutCommandeV2`,
        parameter: "",
        body: middlwareOrder,
    };

    const contentMessageOfMissedTransaction = {
        type: "Put command",
        Uuid: uuidv4(),
        sender: "KIOSK V2",
        content: contentOfMissedTransaction,
        identity: uuidConnectedEntity,
        timestamp: new Date().toLocaleTimeString(),
    };
    let retryCounter = 0;
    const PutCommandFunction = async () => {
        const signal = controller.signal;

        await fetch(`${middlewareApiUrl}/PutCommandeV2`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(middlwareOrder),
            signal,
        })
            .then((response) => response.json())
            .then(async (result) => {
                console.log(
                    "the response of inserting the order is:",
                    JSON.stringify(result)
                );
                if (result.code === 2) {
                    console.log("command inserted with success");
                    retryCounter = 2;
                    putOrderSuccessfullyInserted(result);
                    return;
                } else {
                    console.log(
                        `error while inserting command with retryCounter: ${retryCounter}`
                    );

                    retryCounter += 1;
                    if (retryCounter === MAX_RETRY_ATTAINT) {
                        missedTransactionAgentLocal(
                            contentMessageOfMissedTransaction
                        );
                        putOrderInsertionRefused(middlwareOrder);
                    } else {
                        PutCommandFunction();
                    }
                }
            })
            .catch((error) => {
                console.log(
                    `Error while handling put command request, with message: ${JSON.stringify(
                        error
                    )}`
                );
                console.log(
                    `error while inserting command in catch clause with retryCounter: ${retryCounter}`
                );

                retryCounter += 1;
                if (retryCounter === MAX_RETRY_ATTAINT) {
                    missedTransactionAgentLocal(
                        contentMessageOfMissedTransaction
                    );
                    putOrderInsertionRefused(middlwareOrder);
                } else {
                    PutCommandFunction();
                }
            });
    };

    PutCommandFunction();
}

export async function handlePutClickAndCollectOrder(
    clickAndCollectOrderId: string,
    localPaymentModes: PaymentModesType,
    isCreatingVoucherAccepted: boolean
): Promise<void> {
    const { middlewareApiUrl } = snapshot(store);

    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
        controller.abort();
        setIsCommandInsertionProblemModalOpened(true);
    }, 60000);

    console.log(
        "Put click and collect command function",
        "The order structure:",
        JSON.stringify({ clickAndCollectOrderId, localPaymentModes })
    );
    await fetch(
        `${middlewareApiUrl}/PutCommandClickAndCollect?clickAndCollectOrderId=${clickAndCollectOrderId}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(localPaymentModes),
            signal,
        }
    )
        .then((response) => response.json())
        .then((result) => {
            console.log(
                "the response of inserting the order is:",
                JSON.stringify(result)
            );
            if (result.code === 2) {
                console.log("the order inserted successfully");
                setOrderNumber(
                    String(result.response[0]).slice(
                        8,
                        result.response[0].length
                    )
                );
                console.log(
                    "Inside Put click and collect command func verify if creating voucher accepted?",
                    { isCreatingVoucherAccepted }
                );
                if (isCreatingVoucherAccepted === true) {
                    console.log(
                        "yes!! creating voucher is accepted. So, handlePrintVoucher"
                    );
                    handlePrintVoucher(result.response[1]);
                }
                handlePrintClientTicket(result.response[0]);
                setIsOrderInsertedSuccessfully(true);
            } else {
                console.log(
                    "Oops! There seems to be an error while inserting the order."
                );
                console.log(
                    "SO,refund the deposited amount is necessary, handleRefundDepositedAmount function"
                );

                console.log(
                    "inside put command func regulation list",
                    // eslint-disable-next-line
                    JSON.stringify(localPaymentModes)
                );

                console.log("Command Insertion Problem Modal Is Opened!!");
                setIsCommandInsertionProblemModalOpened(true);
                setIsOrderInsertionRefused(true);
            }
        })
        .catch((error) => {
            console.log(
                `Error while handling put command request, with message: ${JSON.stringify(
                    error
                )}`
            );
            console.log(
                "SO,refund the deposited amount is necessary, handleRefundDepositedAmount function"
            );
            console.log(
                "inside put commande func regulation list",
                // eslint-disable-next-line
                JSON.stringify(localPaymentModes)
            );

            console.log("Command Insertion Problem Modal Is Opened!!");
            setIsCommandInsertionProblemModalOpened(true);
            setIsOrderInsertionRefused(true);
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}

function putOrderSuccessfullyInserted(
    // eslint-disable-next-line
    putOrderResult: any
): void {
    const { isCreatingVoucherAccepted } = snapshot(kioskStore);
    const { posEditor } = snapshot(store);
    const partnerCmdNumber = putOrderResult.response[5];
    if (posEditor === CASHPAD && partnerCmdNumber === undefined) {
        console.log("Command Partner Insertion Problem Modal Is Opened!!");
        setIsCommandInsertionProblemModalOpened(true);
        setIsOrderInsertionRefused(true);
    }
    console.log("the order inserted successfully");

    setOrderNumber(
        posEditor === CASHPAD && partnerCmdNumber !== undefined
            ? String(partnerCmdNumber)
            : String(putOrderResult.response[0]).slice(
                  8,
                  putOrderResult.response[0].length
              )
    );
    console.log(
        "Inside Put command func verify if creating voucher accepted?",
        { isCreatingVoucherAccepted }
    );

    setIsOrderInsertedSuccessfully(true);
    handlePrintClientTicket(putOrderResult.response[0]);
    setIsOrderInsertedSuccessfully(true);
}

export function putOrderInsertionRefused(middlwareOrder: ObjectType): void {
    console.log("Oops! There seems to be an error while inserting the order.");
    console.log(
        "SO,refund the deposited amount is necessary, handleRefundDepositedAmount function"
    );

    console.log(
        "inside put command func regulation list",
        // eslint-disable-next-line
        JSON.stringify((middlwareOrder as any).reglements)
    );

    console.log("Command Insertion Problem Modal Is Opened!!");
    setIsCommandInsertionProblemModalOpened(true);
    setIsOrderInsertionRefused(true);
}

export function getEntityData(
    data: EntityDataType | EntityDataType[]
): EntityType[] {
    let entities: EntityType[] = [];

    if (Array.isArray(data)) {
        entities = data.map((datum: EntityDataType) => getEntityData(datum)[0]);
    } else {
        const entity: EntityType = {
            uid: data["uid"] as string,
            crm_id: data["CRMId"] as string,
            name: data["Nom commercial"] as string,
            franchise: data["Name"] as string,
            schema: data["franchise_schema"] as string,
            nature: data["Nature"] as string,
            type: data["ETKType"] as EntityTypeType,
            accountType: data["Type du compte"] as string,
            address: data["Adresse"] as string,
            children_uid: data["childs_profilelink"] as string[],
            parent: data["parent_profilelink"] as EntityParentType,
            franchiseUuid: data["uuidFranchise"],
            franchiseUuidDatabaseInfo: data["uid_franchise_database_info"],
        };

        if (data["IdStore"] !== undefined) {
            entity.id = data["IdStore"] as string | undefined;
        }

        if (data["subRows"] !== undefined) {
            const children: EntityType[] = [];

            data["subRows"].forEach((subRow: EntityDataType) => {
                children.push(getEntityData(subRow)[0]);
            });

            entity.children = children;
        }

        entities.push(entity);
    }

    return entities;
}

export async function decryptWord(wordToDecrypt: string): Promise<string> {
    try {
        return await fetch(
            `${process.env.REACT_APP_CONSOLE_APP_API}/decryptWord`,
            {
                headers: {
                    Accept: "text/plain",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: `wordToDecrypted=${wordToDecrypt}`,
                method: "POST",
            }
        ).then((response) => {
            return response.text();
        });
    } catch (error) {
        console.error(
            `Error while decrypting word ${wordToDecrypt}, with message: ${JSON.stringify(
                (error as Error).message
            )}`
        );
        return "";
    }
}

export function getYesActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const yesImageSrc = getImageContentById(
        (
            (actionsButtons as ActionsButtonsDesignType).items.find(
                (actionButton) => actionButton.name === "Yes"
            ) as ActionsButtonsDesignItemType
        ).id
    );

    return yesImageSrc;
}

export function getNoActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const noImageSrc = getImageContentById(
        (
            (actionsButtons as ActionsButtonsDesignType).items.find(
                (actionButton) => actionButton.name === "No"
            ) as ActionsButtonsDesignItemType
        ).id
    );

    return noImageSrc;
}

export function getAlertActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const alertImageSrc =
        actionsButtons !== undefined &&
        actionsButtons.items !== undefined &&
        actionsButtons?.items?.length > 0
            ? getImageContentById(
                  (
                      (actionsButtons as ActionsButtonsDesignType).items.find(
                          (actionButton) => actionButton.name === "Alert"
                      ) as ActionsButtonsDesignItemType
                  ).id
              )
            : "./images/alert.png";

    return alertImageSrc;
}

export function getBackButtonImgeSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const backImageSrc =
        actionsButtons !== undefined &&
        actionsButtons.items !== undefined &&
        actionsButtons?.items?.length > 0
            ? getImageContentById(
                  (
                      (actionsButtons as ActionsButtonsDesignType).items.find(
                          (actionButton) => actionButton.name === "Back"
                      ) as ActionsButtonsDesignItemType
                  ).id
              )
            : "./images/back.png";

    return backImageSrc;
}

export function getOtherPaymentMethodButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const otherPaymentMethodImageSrc =
        actionsButtons?.items !== undefined && actionsButtons?.items?.length > 0
            ? getImageContentById(
                  (
                      (actionsButtons as ActionsButtonsDesignType).items.find(
                          (actionButton) =>
                              actionButton.name === "otherPaymentMethod"
                      ) as ActionsButtonsDesignItemType
                  ).id
              )
            : "";

    return otherPaymentMethodImageSrc;
}

export function getPeripheralDeviceImageSrc(item: string): string {
    if (item.toLowerCase() === "change machine") {
        return "cash-management";
    }
    if (item.toLowerCase() === "printer") {
        return item.toLowerCase();
    }
    if (item.toLowerCase() === "led") {
        return item.toLowerCase();
    }
    if (item.toLowerCase() === "tpe") {
        return item.toLowerCase();
    }
    if (item.toLowerCase() === "scanner") {
        return "led";
    }
    return "placeholder";
}

export function removeItemFromList(
    list: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[],
    index: number
): [
    (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    ),
    (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[]
] {
    const result: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[] = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
}

export function addItemInList(
    list: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[],
    index: number,
    element:
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
): (
    | PageType
    | ConsommationPageType
    | StandbyScreenPageType
    | PaymentPageType
    | OrderTakingPageType
    | PrinterOptionsPageType
)[] {
    const result: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType
    )[] = Array.from(list);
    result.splice(index, 0, element);
    return result;
}

export function updateStepsProject(project: ProjectType): void {
    const localKioskWays = useLocalWays(project);

    setKioskWays(localKioskWays);

    setProjectTimeout(project.template.timeout);

    setIsConfigOptionsActive(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).active
    );

    setActiveConfigOptions(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).items.filter((option) => option.active === true)
    );

    setIsLanguagesActive(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).active &&
            (
                (project.template.content as ProjectContentType)
                    .languages as LanguagesType
            )?.items.filter((option) => option.active === true).length !== 1
    );

    setActiveLanguages(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).items.filter((language) => language.active === true)
    );

    setCustomerLanguage(
        (project.template.content.languages as LanguagesType).items.filter(
            (language) => language.isDefault === true
        )[0]
    );

    setInitialStepTimeout(project.template.initialStepTimeout as number);

    setLogo((project.template.content.generalDesign as GeneralDesignType).logo);
}

export function getProjectByFranchiseUidAndShopUid(
    franchise_uid: string,
    shop_uid: string,
    // eslint-disable-next-line
    dispatch: Dispatch<any>
): void {
    const { middlewareApiUrl } = snapshot(store);
    const { selectedShopApp } = snapshot(syncStore);
    const timestamp = new Date().getTime();

    fetch(
        `${middlewareApiUrl}/settings/consomation?franchise_uuid=${franchise_uid}&shop_uuid=${shop_uid}&nameKiosk=${selectedShopApp.name}&timestamp=${timestamp}`
    )
        .then((response) => response.json())
        .then(({ kiosk }) => {
            if (kiosk.length > 0) {
                setPosEditor(kiosk[0].posEditor);
            }
            const modifiedProject = kiosk.filter(
                (kiosk: Record<string, string>) => {
                    return kiosk.name === selectedShopApp.name;
                }
            );
            if (Array.isArray(modifiedProject) && modifiedProject.length > 0) {
                setProject(modifiedProject[0].project);
                updateStepsProject(modifiedProject[0].project);

                dispatch(
                    setSetting(
                        setOrderTakingSetting(
                            modifiedProject[0].project.template.pages.ways
                                .orderTaking as PageType,

                            (
                                modifiedProject[0].project.template.content
                                    .generalDesign as GeneralDesignType
                            ).colors[0].content,
                            (
                                modifiedProject[0].project.template.content
                                    .generalDesign as GeneralDesignType
                            ).colors[1].content
                        )
                    )
                );

                if (process.env.REACT_APP_ELECTRON_ENV !== "dev") {
                    (window as RendererWindow).electronAPI?.getProjectEvent({
                        data: JSON.stringify(
                            modifiedProject[0].project,
                            null,
                            4
                        ),
                    });
                }
            }
        })
        .catch((error) =>
            console.log(
                `Error while updating project from setting, with message: ${JSON.stringify(
                    error
                )}`
            )
        );
}

export function acceptVoucherGetting(totalPrice: number): void {
    const {
        leftToPayAfterCashMachineValidation,
        cashUuID,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        totalAmountDeposited,
        paymentModes,
        selectedRegulationModeValue,
        voucherAmount,
    } = snapshot(kioskStore);

    const selectedPaymentMode = cloneDeep(
        paymentModes.find(
            (mode) => mode.paymentMode === selectedRegulationModeValue
        )
    );

    if (selectedPaymentMode !== undefined) {
        const selectedPaymentModeIndex = paymentModes.findIndex(
            (mode) => mode.paymentMode === selectedRegulationModeValue
        );

        const localPaymentModes = cloneDeep(
            paymentModes.map((paymentMode) => {
                return paymentMode;
            })
        );

        localPaymentModes[selectedPaymentModeIndex] = {
            paymentModeUuiD: cashUuID,
            paymentMode: selectedRegulationModeValue,
            paymentAmount:
                totalAmountDeposited + leftToPayAfterCashMachineValidation,
            data: {
                renderAmount: 0,
            },
            totalAmountDeposited:
                totalAmountDeposited + leftToPayAfterCashMachineValidation,
        };

        console.log(
            "JSON.stringify(PaymentModes) in PaymentStep code 999 IF",
            JSON.stringify([
                ...localPaymentModes,
                {
                    paymentModeUuiD: "",
                    paymentMode: "voucher",
                    //avoir rendu negatif
                    paymentAmount: -voucherAmount,
                    data: {
                        renderAmount: 0,
                        mode_reglement: "ESP",
                    },
                    totalAmountDeposited: 0,
                },
            ])
        );
        setPaymentModes([
            ...localPaymentModes,
            {
                paymentModeUuiD: "",
                paymentMode: "voucher",
                //avoir rendu negatif
                paymentAmount: -voucherAmount,
                data: {
                    renderAmount: 0,
                    mode_reglement: "ESP",
                },
                totalAmountDeposited: 0,
            },
        ]);
    } else {
        console.log(
            "JSON.stringify(PaymentModes) in PaymentStep code 999 ELSE",
            JSON.stringify([
                ...paymentModes,
                {
                    paymentModeUuiD: cashUuID,
                    paymentMode: "changeMachine",
                    paymentAmount: isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : totalPrice,
                    data: {
                        renderAmount: 0,
                    },
                    totalAmountDeposited:
                        isOrderPartialPaidWithLoyaltyAccountBalance
                            ? totalOrderAfterPaidWithLoyaltyAccountBalance
                            : totalPrice,
                },
                {
                    paymentModeUuiD: "",
                    paymentMode: "voucher",
                    //avoir rendu negatif
                    paymentAmount: -voucherAmount,
                    data: {
                        renderAmount: 0,
                        mode_reglement: "ESP",
                    },
                    totalAmountDeposited: 0,
                },
            ])
        );
        setPaymentModes([
            ...paymentModes,
            {
                paymentModeUuiD: cashUuID,
                paymentMode: "changeMachine",
                paymentAmount: isOrderPartialPaidWithLoyaltyAccountBalance
                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                    : totalPrice,
                data: {
                    renderAmount: 0,
                },
                totalAmountDeposited:
                    isOrderPartialPaidWithLoyaltyAccountBalance
                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                        : totalPrice,
            },
            {
                paymentModeUuiD: "",
                paymentMode: "voucher",
                //avoir rendu negatif
                paymentAmount: -voucherAmount,
                data: {
                    renderAmount: 0,
                    mode_reglement: "ESP",
                },
                totalAmountDeposited: 0,
            },
        ]);
    }

    setIsCreatingVoucherAccepted(true);
}

export function getFilenameFromUrl(url: string, path: string): string {
    const { middlewareApiUrl } = snapshot(store);
    if (process.env.REACT_APP_MODE === CATALOGUE_MODE) {
        return url;
    }
    try {
        const imageName = url.split("/").pop();
        const expectedImage = ["no-pictures.svg", "nopicture.png"];
        const assetsNewUrl =
            imageName && !expectedImage.includes(imageName)
                ? `${middlewareApiUrl}/static/${path}/${imageName}`
                : "./images/nopicture.png";

        return assetsNewUrl;
    } catch (error) {
        console.error(`getFilenameFromUrl error:url:${url} `);
        return "./images/nopicture.png";
    }
}

export function getGiveUpActionButtonImageSrc(
    actionsButtons: ActionsButtonsDesignType
): string {
    const giveUpImageSrc = getImageContentById(
        (
            (actionsButtons as ActionsButtonsDesignType).items.find(
                (actionButton) => actionButton.name === "giveUp"
            ) as ActionsButtonsDesignItemType
        ).id
    );

    return giveUpImageSrc;
}

export function connectWebSocket(): void {
    const middlewareApiUrl = localStorage.getItem("middlewareApiUrl") as string;

    const url =
        middlewareApiUrl.length > 0
            ? "ws://" + middlewareApiUrl.split("/")[2].split(":")[0] + ":8181"
            : "ws://localhost:8181";
    const ws = new WebSocket(url);
    ws.onopen = function () {
        console.log("TCPOS Socket Connection is Opened");
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ws.onmessage = async function ({ data }: any) {
        const { eventType } = JSON.parse(
            JSON.parse(JSON.stringify(data.toString()))
        );
        switch (eventType) {
            case "refreshDataNeeded": {
                refreshDataNeedTcpos();
                break;
            }
            case "articleAddedByBarcode": {
                await refreshTcPosOrder();
                break;
            }
            case "customerAdded": {
                await refreshTcPosOrder();
                break;
            }
            case "changeStatus": {
                await refreshTcPosOrder();
                break;
            }
            case "quantityModified": {
                await refreshTcPosOrder();
                break;
            }
            case "quantitySupressed": {
                await refreshTcPosOrder();
                break;
            }
        }
    };
    ws.onclose = function () {
        console.log("TCPOS Socket Connection is closed...");
    };
}

export function VerifyIsPrinterStepWithPrintTicketButton(): boolean {
    const {
        navigationIndex,
        kioskWays,

        project: {
            template: { pages },
        },
        isUserAuthenticated,
        isSendEmailConfirmed,
        user,
    } = snapshot(kioskStore);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const validEmail =
        (pages.ways.printerOptions as PrinterOptionsPageType) !== undefined
            ? isUserAuthenticated &&
              (pages.ways.printerOptions as PrinterOptionsPageType)
                  .printerOptionsConfig.isConfirmationEmailActive === false
                ? emailRegex.test(user.email)
                : true
            : true;

    return (pages.ways.printerOptions as PrinterOptionsPageType) !== undefined
        ? (((pages.ways.printerOptions as PrinterOptionsPageType)
              .printerOptionsConfig.isEmailActive &&
              isUserAuthenticated &&
              validEmail) ||
              ((pages.ways.printerOptions as PrinterOptionsPageType)
                  .printerOptionsConfig.isUnauthenticatedEmailActive &&
                  !isUserAuthenticated)) &&
              kioskWays[navigationIndex]?.name === "printerOptions" &&
              !isSendEmailConfirmed
        : false;
}

export function VerifyIsPrinterStepWithEmail(): boolean {
    const {
        project: {
            template: { pages },
        },
        isUserAuthenticated,
        user,
    } = snapshot(kioskStore);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const validEmail =
        (pages.ways.printerOptions as PrinterOptionsPageType) !== undefined
            ? isUserAuthenticated &&
              (pages.ways.printerOptions as PrinterOptionsPageType)
                  .printerOptionsConfig.isConfirmationEmailActive === false
                ? emailRegex.test(user.email)
                : true
            : true;
    return (pages.ways.printerOptions as PrinterOptionsPageType) !== undefined
        ? ((pages.ways.printerOptions as PrinterOptionsPageType)
              .printerOptionsConfig.isEmailActive &&
              isUserAuthenticated &&
              validEmail) ||
              ((pages.ways.printerOptions as PrinterOptionsPageType)
                  .printerOptionsConfig.isUnauthenticatedEmailActive &&
                  !isUserAuthenticated)
        : false;
}

export async function handleLedControl(
    connectedPort: string,
    ledColor: string
): Promise<void> {
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);
    await fetch(`${middlewareApiUrl}/LedControl?timestamp=${timestamp}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            Port: connectedPort,
            color: ledColor,
        }),
    })
        .then((response) => response.json())
        .then((result) => {
            console.log(
                "the response of sending led data:",
                JSON.stringify(result)
            );
            if (result.code === 2) {
                return console.log("the result code 2");
            }
        })
        .catch((error) =>
            console.error(
                `Error while sending led data:${JSON.stringify(error)}`
            )
        );
}

export function convertSelectedSalesModeValue(saleModeCode: number): string {
    switch (saleModeCode) {
        case 1:
            return "Delivery";
        case 2:
            return "To take";
        case 3:
            return "On the spot";
        default:
            return "To take";
    }
}

export async function handleLoginLocalSettingsButtonOnClickEvent(
    encodedEmail: string,
    encodedPassword: string,
    setIsErrorLogInModalWrapperOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >,
    setLoginButton: React.Dispatch<React.SetStateAction<string>>
    // eslint-disable-next-line
): Promise<any> {
    const { middlewareApiUrl } = snapshot(store);
    const uuidConnectedEntity = localStorage.getItem("connectedUser") as string;
    const timestamp = new Date().getTime();
    const url = `${middlewareApiUrl}/verifyKioskLocalAccount?timestamp=${timestamp}`;
    const shopDetail = localStorage.getItem("shopDetail")
        ? JSON.parse(localStorage.getItem("shopDetail") as string)
        : {};
    const { project } = snapshot(kioskStore);
    const usersToken = localStorage.getItem("usersToken");
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
        controller.abort();
        setIsTechProblemModalOpened(true, NETWORK_PROBLEM);
        setLoginButton("Login");
    }, 30000);
    fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `email=${encodedEmail}&password=${encodedPassword}&uuidEntity=${uuidConnectedEntity}&uuidShop=${shopDetail.shop_uid}&usersToken=${usersToken}`,
        method: "POST",
    })
        .then((response) => response.json())
        .then(async (result) => {
            if (result.error === true) {
                setIsErrorLogInModalWrapperOpened(true);
                setLoginButton("Login");
            } else if (result.tokenAccess) {
                const tokens = JSON.parse(usersToken as string);
                const connectedUserToken =
                    tokens[decodeURIComponent(encodedEmail)];
                console.log(
                    `Logged using MiddleWare with email ${encodedEmail} access token : ${JSON.stringify(
                        tokens[decodeURIComponent(encodedEmail)].token
                    )}`
                );
                setConnectedUser({
                    email: decodeURIComponent(encodedEmail),
                    firstName: connectedUserToken.firstName,
                });
                setLocalProject(project);
                setPagesItems(project.template.pages);
                setIsAuthenticationModalOpened(false);
                setIsLocalSettingModalOpened(false);
                setIsLocalConfigModalOpened(true);
                setLoginButton("Login");
                localStorage.setItem(
                    "EmailLocalUser",
                    decodeURIComponent(encodedEmail)
                );
                setIsSubRowEntityClicked(false);
            } else if (
                result.data.profileAuthorization[KIOSK_AUTHORIZATION_ID]
                    .Modifie === false
            ) {
                setIsAccessNotAllowed(true);
                setIsErrorLogInModalWrapperOpened(true);
                setLoginButton("Login");
            } else {
                localStorage.setItem(
                    "usersToken",
                    JSON.stringify(result.usersToken)
                );
                setConnectedUserEntity(result.data.uidEntity);
                setConnectedUser({
                    email: result.data.email,
                    firstName: result.data.firstName,
                });
                localStorage.setItem("EmailLocalUser", result.data.email);

                setLocalProject(project);
                setPagesItems(project.template.pages);
                setIsAuthenticationModalOpened(false);
                setIsLocalSettingModalOpened(false);
                setIsLocalConfigModalOpened(true);
                setLoginButton("Login");
            }
        })
        .catch((error: unknown) => {
            const tokens = JSON.parse(usersToken as string);
            if (tokens !== null && tokens[decodeURIComponent(encodedEmail)]) {
                const connectedUserToken =
                    tokens[decodeURIComponent(encodedEmail)];
                setConnectedUser({
                    email: decodeURIComponent(encodedEmail),
                    firstName: connectedUserToken.firstName,
                });
                localStorage.setItem(
                    "EmailLocalUser",
                    decodeURIComponent(encodedEmail)
                );
                setLocalProject(project);
                setPagesItems(project.template.pages);
                setIsAuthenticationModalOpened(false);
                setIsLocalSettingModalOpened(false);
                setIsLocalConfigModalOpened(true);
                setLoginButton("Login");
                console.log(
                    `Loged in CATCH ERROR with email ${encodedEmail} access token : ${JSON.stringify(
                        usersToken
                    )}`
                );
                return;
            }
            console.log(
                `Error while verifying custom account, with message ${JSON.stringify(
                    (error as Error).message
                )}`
            );
            setIsTechProblemModalOpened(true, NETWORK_PROBLEM);
            setLoginButton("Login");
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}
export async function handleLoginSynchronizationButtonOnClickEvent(
    encodedEmail: string,
    encodedPassword: string,
    setIsErrorLogInModalWrapperOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >,
    setLoginButton: React.Dispatch<React.SetStateAction<string>>
    // eslint-disable-next-line
): Promise<any> {
    const { middlewareApiUrl } = snapshot(store);
    const timestamp = new Date().getTime();
    const url = `${middlewareApiUrl}/verifyKioskAccount?timestamp=${timestamp}`;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
        controller.abort();
        setIsTechProblemModalOpened(true, NETWORK_PROBLEM);
        setLoginButton("Login");
    }, 30000);
    await fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `email=${encodedEmail}&password=${encodedPassword}`,
        method: "POST",
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.error === true) {
                setIsErrorLogInModalWrapperOpened(true);
                setLoginButton("Login");
            } else {
                localStorage.setItem(
                    "connectedUser",
                    data["data"]["uidEntity"]
                );
                setConnectedUserEntity(data["data"]["uidEntity"]);
                setUidConnectedUser(data["data"]["uidUser"]);
                setConnectedUser({
                    email: data["data"].email,
                    firstName: data["data"].firstName,
                });

                setIsEntitySelectionModalOpened(true);
                setIsAuthenticationModalOpened(false);
                setLoginKeyboardInputsValues({
                    email: "",
                    password: "",
                });
                setLoginButton("Login");
            }
        })
        .catch((error) => {
            console.log(
                `Error while verifying customer account, with message: ${JSON.stringify(
                    error
                )}`
            );
            setIsTechProblemModalOpened(true, NETWORK_PROBLEM);
            setLoginButton("Login");
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}

export function middleWareOrderTrace(orderItems: any[]): string {
    const lines: any[] = [];
    orderItems.forEach((item: any) => {
        if (Object.keys(item?.shopCart).length === 0) {
            const totalPrice = item.quantity * item.price;
            lines.push(
                `${item.quantity} * ${item.designation} : ${totalPrice}`
            );
        } else {
            const totalPrice = item.quantity * item.defaultPrice;
            lines.push(
                `${item.quantity} * ${item.designation} : ${totalPrice}`
            );

            fillMiddlwareOrder(item.shopCart, lines);
        }
    });
    return lines.join().replaceAll(",", "\n");
}

function fillMiddlwareOrder(compoundItem: any, lines: any[]) {
    Object.values(compoundItem).forEach((item: any) => {
        if (item?.compositions !== undefined) {
            if (item.name) {
                const totalPrice = item.quantity * item.defaultPrice;
                lines.push(
                    `${item.quantity} * ${item.designation} : ${totalPrice}`
                );
            }

            Object.values(item?.compositions || {}).forEach((item: any) => {
                if (item.compositions === undefined) {
                    const itemPrice =
                        item.path[item.path.length - 3].name ===
                        BASIC_COMPOSITION_TYPE
                            ? 0
                            : item.price;
                    const totalPrice = item.quantity * itemPrice;
                    lines.push(
                        `${item.quantity} * ${item.designation} : ${totalPrice}`
                    );
                } else {
                    if (item.name) {
                        const totalPrice = item.quantity * item.price;
                        lines.push(
                            `${item.quantity} * ${item.designation} : ${totalPrice}`
                        );
                    }
                    fillMiddlwareOrder(item.compositions, lines);
                }
            });
        } else {
            const itemPrice =
                item.path[item.path.length - 3].name === BASIC_COMPOSITION_TYPE
                    ? 0
                    : item.price;

            const totalPrice = item.quantity * itemPrice;
            lines.push(
                `${item.quantity} * ${item.designation} : ${totalPrice}`
            );
        }
    });
}
export function getImageContentByIdV2(item: RefactoredLanguageType): string {
    const { customerLanguage } = snapshot(kioskStore);
    const imageContent = item[customerLanguage.name];

    if (imageContent === "") {
        return "";
    }
    return VALIDATED_EXTENSION_FILE.includes(imageContent.substr(-4))
        ? imageContent?.includes("http")
            ? getFilenameFromUrl(imageContent, "template")
            : `./images/${imageContent}`
        : imageContent;
}

export function phoneNumberValidation(
    phoneNumber: string,
    countryCode: CountryCode
): boolean {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, countryCode);

    return phoneNumberObj
        ? isPossiblePhoneNumber(phoneNumberObj.number)
        : false;
}

export const validationSchema = Yup.object().shape({
    phoneNumber: Yup.number()
        .integer("Invalid number")
        .required(`${t("phoneNumber") + " " + t("is required")}`)
        .test(
            "isValidPhoneNumber",
            t("Phone number must start with 0 and be exactly 10 digits long"),

            function (value: number) {
                if (value.toString() === "") return true;
                const isPhoneValid = phoneNumberValidation(
                    value.toString(),
                    DEFAULT_COUNTRY_CODE_FR
                );
                return isPhoneValid;
            }
        ),
    email: Yup.string().email(() => `${t("Invalid email")}`),
    firstName: Yup.string()
        .matches(/^[A-Za-z ]*$/, t("firstName must only contain letters"))
        .required(`${t("firstName") + " " + t("is required")}`),
    name: Yup.string().matches(
        /^[A-Za-z ]*$/,
        t("Name must only contain letters")
    ),
    country: Yup.string(),
    postalCode: Yup.string().matches(
        /^[0-9]+$/,
        t("Postal code must only contain numbers")
    ),
});
