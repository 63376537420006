export const transitions = {
    none: {
        prettyName: "None",
        timeout: 0,
        className: "none",
    },
    fade: {
        prettyName: "Fade",
        timeout: 1000,
        className: "fade",
    },
    slide: {
        prettyName: "Slide",
        timeout: 4500,
        className: "slide",
    },
    rotate: {
        prettyName: "Rotate",
        timeout: 300,
        className: "rotate",
    },
    leftToRight: {
        prettyName: "Left to right",
        timeout: 4500,
        className: "left-to-right",
    },
    rightToLeft: {
        prettyName: "Right to left",
        timeout: 4500,
        className: "right-to-left",
    },
    topToBottom: {
        prettyName: "Top to bottom",
        timeout: 4500,
        className: "top-to-bottom",
    },
};

export const TRANSITION_VARIANTS = {
    none: {
        enter: {},
        exit: {},
        enterActive: {},
        exitActive: {},
        transition: { duration: 0 },
    },
    fade: {
        enter: {
            opacity: 0,
        },
        exit: {
            opacity: 1,
        },
        enterActive: {
            opacity: 1,
            transition: "opacity 500ms",
        },
        exitActive: {
            opacity: 0,
            transition: "opacity 500ms",
        },
        transition: { duration: 1000 },
    },
    slide: {
        enter: {
            opacity: 0,
            transform: "scale(0.6) translateY(30%)",
        },
        exit: {
            opacity: 1,
            transform: "translateX(0)",
        },
        enterActive: {
            opacity: 1,
            transform: "scale(1) translateY(0)",
        },
        exitActive: {
            opacity: 0,
            transform: "translateX(30%)",
        },
        transition: { duration: 4500 },
    },
    rotate: {
        enter: {
            transform: "rotateY(90deg) rotateX(45deg)",
        },
        exit: {
            transform: "rotateY(0deg) rotateX(0deg)",
        },
        enterActive: {
            transform: "rotateY(0deg) rotateX(0deg)",
            transition: " transform 1s cubic-bezier(0.34, 1.56, 0.64, 1)",
        },
        exitActive: {
            transform: "rotateY(-50deg) rotateX(-20deg)",
        },
        transition: { duration: 300, ease: [0.34, 1.56, 0.64, 1] },
    },
    leftToRight: {
        enter: {
            opacity: 0,
            transform: "translateX(-100%)",
        },
        exit: {
            opacity: 1,
            transform: "translateX(0)",
        },
        enterActive: {
            opacity: 1,
            transform: "translateX(0)",
            transition: "opacity 300ms, transform 300ms",
        },
        exitActive: {
            opacity: 0,
            transform: "translateX(-100%)",
            transition: "opacity 300ms, transform 300ms",
        },
        transition: { duration: 4500 },
    },
    rightToLeft: {
        enter: {
            opacity: 0,
            transform: "translateX(100%)",
        },
        exit: {
            opacity: 1,
            transform: "translateX(0)",
        },
        enterActive: {
            opacity: 1,
            transform: "translateX(0)",
            transition: "opacity 300ms, transform 300ms",
        },
        exitActive: {
            opacity: 0,
            transform: "translateX(100%)",
            transition: "opacity 300ms, transform 300ms",
        },
        transition: { duration: 4500 },
    },
    topToBottom: {
        enter: {
            opacity: 0,
            transform: "translateY(-30%) translateX(0)",
        },
        exit: {
            opacity: 1,
            transform: "translateY(0) translateX(0)",
        },
        enterActive: {
            opacity: 1,
            transform: "translateY(0) translateX(0)",
        },
        exitActive: {
            opacity: 0,
            transform: "translateY(-30%) translateX(0)",
            transition: "opacity 300ms, transform 300ms",
        },
        transition: { duration: 4500 },
    },
};
