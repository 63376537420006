import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";

import { formatCurrency } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export default function RefundCashMachineModal(): JSX.Element {
    const {
        voucherAmount,
        cashMachineStatus,
        leftToPayAfterCashMachineValidation,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        orderTotalPrice,
    } = useSnapshot(kioskStore);
    const { t } = useTranslation();

    const localTotalPrice = isOrderPartialPaidWithLoyaltyAccountBalance
        ? totalOrderAfterPaidWithLoyaltyAccountBalance
        : orderTotalPrice;

    const unitPrice =
        leftToPayAfterCashMachineValidation !== 0
            ? leftToPayAfterCashMachineValidation
            : localTotalPrice;
    return (
        <>
            <div className="d-flex justify-content-center align-items-center w-100 refund-cash-machine-container">
                <span>{t("Refund anomaly")}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center text-center  w-100 refund-cash-machine-container">
                <span className="refund-cash-machine-text">
                    {t(
                        "Not enough coins or banknotes to finalize the transaction."
                    )}
                </span>
            </div>
            <div className="d-flex justify-content-center align-items-center text-center  w-100 refund-cash-machine-container">
                <span className="refund-cash-machine-description">
                    {cashMachineStatus.isRefundProblem
                        ? t("Please collect your voucher {{voucherAmount}}", {
                              voucherAmount: formatCurrency(voucherAmount),
                          })
                        : t(
                              "Do you want to create a voucher to have {{voucherAmount}}",
                              {
                                  voucherAmount: formatCurrency(
                                      voucherAmount - unitPrice
                                  ),
                              }
                          )}
                </span>
            </div>
        </>
    );
}
