import { StyledButton } from "@aureskonnect/react-ui";
import { VALIDATED_EXTENSION_FILE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import {
    getImageContentById,
    getOtherPaymentMethodButtonImageSrc,
} from "@helpers/general";

import {
    kioskStore,
    setIsAbortTransactionModalOpened,
    setIsRegulationModesModalOpened,
    setIsTpeNotAvailable,
    setNavigationIndex,
} from "@pages/Kiosk/store";

export default function TpeActionButtons(): JSX.Element {
    return (
        <>
            <OtherPayementsMethod />
            <CancelMyOrderButton />
        </>
    );
}

function CancelMyOrderButton(): JSX.Element {
    const { actionsButtons: basketSettingsActionsButtons } = useSelector(
        // eslint-disable-next-line
        (state: any): any => state.settingSlice.basketSettings
    );
    const givupButton = basketSettingsActionsButtons.filter(
        // eslint-disable-next-line
        (button: any) => button.name.toLowerCase() !== "pay"
    );
    const givupButtonImageSrc = getImageContentById(givupButton[0].id);
    return VALIDATED_EXTENSION_FILE.includes(givupButtonImageSrc.substr(-4)) ? (
        <img
            src={givupButtonImageSrc}
            style={{
                width: "22rem",
                height: "5rem",
                imageRendering: "-webkit-optimize-contrast",
            }}
            onClick={() => {
                setNavigationIndex(0);
            }}
        />
    ) : (
        <StyledButton
            rounded={true}
            className="m-2"
            style={{
                height: "5rem",
                width: "22rem",
                background: "black",
                fontSize: "29px",
                fontFamily: "HelveticaNeue-Medium, Helvetica Neue",
                fontWeight: "500",
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #FFFFFF",
                borderRadius: "12px",
                marginRight: "0px",
            }}
            onClick={() => {
                setNavigationIndex(0);
            }}
        >
            {t("Cancel my order")}
        </StyledButton>
    );
}

function OtherPayementsMethod(): JSX.Element {
    const {
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(kioskStore);

    const otherPaymentMethodImgSrc = getOtherPaymentMethodButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );
    return VALIDATED_EXTENSION_FILE.includes(
        otherPaymentMethodImgSrc.substr(-4)
    ) ? (
        <img
            src={otherPaymentMethodImgSrc}
            alt="action button"
            style={{
                width: "22rem",
                height: "5rem",
                imageRendering: "-webkit-optimize-contrast",
            }}
            onClick={() => {
                console.log(
                    "otherPaymentMethod!!",
                    "open the other payment modes modal!!"
                );
                setIsTpeNotAvailable(false);
                setIsRegulationModesModalOpened(true);
                setIsAbortTransactionModalOpened(false);
            }}
        />
    ) : (
        <StyledButton
            rounded={true}
            className="m-2"
            style={{
                height: "5rem",
                width: "22rem",
                background: "black",
                fontSize: "29px",
                fontFamily: "HelveticaNeue-Medium, Helvetica Neue",
                fontWeight: "500",
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #FFFFFF",
                borderRadius: "12px",
                marginRight: "0px",
            }}
            onClick={() => {
                console.log(
                    "otherPaymentMethod!!",
                    "open the other payment modes modal!!"
                );
                setIsTpeNotAvailable(false);
                setIsRegulationModesModalOpened(true);
                setIsAbortTransactionModalOpened(false);
            }}
        >
            {t("Other means of payment")}
        </StyledButton>
    );
}
