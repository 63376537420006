import { TRANSITION_VARIANTS } from "@constants";
import { motion } from "framer-motion/dist/framer-motion";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

// eslint-disable-next-line
export function AnimatesProducts({ children }) {
    const nextPreviousStep = useSelector(
        (state) => state.workFlowDataSlice.nextPreviousStep,
        shallowEqual
    );

    const { productAnimation } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );

    const animationKey =
        nextPreviousStep + workflowData[workflowData.length - 1].index;

    return (
        <motion.div
            key={animationKey}
            initial={TRANSITION_VARIANTS[productAnimation].enter}
            animate={TRANSITION_VARIANTS[productAnimation].enterActive}
            exit={TRANSITION_VARIANTS[productAnimation].exit}
            variants={TRANSITION_VARIANTS[productAnimation]}
        >
            {children}
        </motion.div>
    );
}
